<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-data-table
      dense
      :class="table"
      style="cursor:pointer"
      sort-by="ad_start_date"
      sort-desc
      :height="tableSize"
      :loading="loading"
      :headers="headers"
      :items.sync="adPlanners"
      :items-per-page="pageSize"
      item-key="adId"
      color="#fff"
      fixed-header
      loading-text="Loading... Please Wait"
      no-results-text="No Matching Ad Planners Found"
      :no-data-text="selectedStore ? 'No Ad Planners Found' : 'Please Select Store to View Ad Planners'"
      @click:row="viewAdPlanner"
      hide-default-footer>
      <template #top>
        <v-toolbar flat color="toolbar" height="75" style="cursor:default;">
          <v-toolbar-title>Ad Planners</v-toolbar-title>
          <v-divider vertical inset class="mx-4"/>
          <v-row dense align="center">
            <v-col md="4" lg="3">
              <v-autocomplete
                :class="body"
                auto-select-first
                v-model="selectedAdGroup"
                :items="userAdGroups"
                item-text="name"
                item-value="id"
                return-object
                hide-no-data
                class="mt-7"
                background-color="input"
                label="Ad Group"
                outlined
                dense
                prepend-inner-icon="mdi-filter-outline"
                :menu-props="{ maxHeight: 260 }"
                :rules="[v => !!v ||  'Required']">
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="text-capitalize">
                        Ad Group
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="3">
            <v-autocomplete
              :class="body"
              auto-select-first
              v-model="selectedStore"
              :items="storeParties"
              item-text="name"
              item-value="id"
              return-object
              class="mt-7"
              background-color="input"
              placeholder="Select Store"
              label="Store"
              outlined
              dense
              prepend-inner-icon="mdi-filter-outline"
              :menu-props="{ maxHeight: 260, rounded: '' }"
              :disabled="!selectedAdGroup"
              :rules="[v => !!v ||  'Required']">
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="text-capitalize">
                        Store
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>

      <template #[`item.ad_details`]="{ item }">
        <v-menu
          open-on-hover
          offset-y
          bottom
          :close-on-content-click="false">
          <template #activator="{ on }">
            <v-btn
              v-on="on"
              icon
              @click.stop="">
              <v-icon>mdi-information</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Ad ID</v-list-item-subtitle>
                  <v-list-item-title>{{ item.ad_id }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Created On</v-list-item-subtitle>
                  <v-list-item-title>{{
                    formatDateTimestamp(item.created_on)
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Created By</v-list-item-subtitle>
                  <v-list-item-title>{{ item.created_by }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </template>

      <template #[`item.ordering_status`]="{ item }">
        <v-chip class="mr-4" small dark :class="statusDisplay(item.ad_start_date, 'color')">
          <span style=" display: inline-block; margin: 0 auto; width: 120px; text-align: center;">
            <span>{{ statusDisplay(item.ad_start_date, 'text') }}</span>
          </span>
        </v-chip>
      </template>

      <template #[`item.last_shipper_date`]="{ item }">
        {{ formatDateDisplay(orderingDates(item.ad_start_date, 'last_shipper')) }}
      </template>

      <template #[`item.last_order_date`]="{ item }">
        {{ formatDateDisplay(orderingDates(item.ad_start_date, 'last_order')) }}
      </template>

      <template #[`item.ad_start_date`]="{ item }">
        {{formatDateDisplay(item.ad_start_date)}} - {{formatDateDisplay(item.ad_end_date)}}
      </template>

      <template #[`item.audit.updated_on`]="{ item }">
        {{formatDateTimestamp(item.audit.updated_on)}}
      </template>

      <template v-slot:footer>
        <v-divider></v-divider>
        <v-toolbar flat color="toolbar">
          <v-row align="center">
            <v-spacer></v-spacer>
            <v-col cols="2" style="max-width:150px;">
              <v-select
                class="mt-8"
                dense
                :items="pageSizes"
                label="Items Per Page"
                v-model="pageSize"
                :menu-props="{ top: true, offsetY: true, maxHeight: 500 }">
              </v-select>
            </v-col>
            <v-btn
              icon
              large
              @click="previous"
              :disabled="disablePrevious"
              class="mx-2">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <small>Page {{ page }}</small>
            <v-btn icon large
              @click="next"
              :disabled="disableNext"
              class="mx-2">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-row>
        </v-toolbar>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
// api
import AdRetails from '@/axios/ad-retails'
import Party from '@/axios/party'
// mixins
import { displayAlert } from '@/mixins/alert'
import { fullwidth } from '@/mixins/fullwidth'
import { partySearch } from '@/mixins/party-search'
import { paging } from '@/mixins/paging'
import { displayHelpers } from '@/mixins/displayHelpers'
import { userAccess } from '@/mixins/user-access'
// third party
import moment from 'moment'

export default {
  name: 'RetailAdManager',
  mixins: [displayAlert, fullwidth, displayHelpers, partySearch, paging, userAccess],
  data () {
    return {
      adDate: '',
      adPlanners: [],
      selectedAdGroup: null,
      storeParties: [],
      selectedStore: null,
      loading: false,
      menu: false,
      modal: false
    }
  },
  watch: {
    selectedAdGroup: {
      handler (newValue, oldValue) {
        if (newValue) {
          sessionStorage.setItem('selected_group', JSON.stringify(newValue))
          if (oldValue) sessionStorage.removeItem('selected_store')
          this.getStoreParties()
          this.getAdPlanners()
        }
      },
      deep: true
    },
    selectedStore: {
      handler (newValue) {
        if (newValue) {
          sessionStorage.setItem('selected_store', JSON.stringify(newValue))
        }
      },
      deep: true
    },
    adDate: {
      handler () {
        this.getAdPlanners()
      }
    },
    userAdGroups: {
      handler (newValue) {
        if (newValue?.length > 0 && !this.selectedAdGroup) {
          if (this.tenant === 'pricechopper') {
            this.selectedAdGroup = newValue.find(obj => obj.name === 'PCE Ad Group')
          } else {
            this.selectedAdGroup = newValue[0]
          }
        }
      },
      deep: true
    },
    tenantStores: {
      handler (newValue) {
        if (newValue?.length > 0 && this.tenant === 'pricechopper' && this.selectedAdGroup.name === 'PCE Ad Group') {
          this.getStoreParties()
        }
      }
    }
  },
  beforeCreate () {
    this.$store.dispatch('getAdGroups')
  },
  created () {
    this.nonTableHeight = 235
    this.tableChanged = this.tableChange(this.getAdPlanners)
    if (sessionStorage.selected_group) {
      this.selectedAdGroup = JSON.parse(sessionStorage.selected_group)
    }
    if (this.userAdGroups.length > 0 && !this.selectedAdGroup) {
      if (this.tenant === 'pricechopper') {
        this.selectedAdGroup = this.userAdGroups.find(obj => obj.name === 'PCE Ad Group')
      } else {
        this.selectedAdGroup = this.userAdGroups[0]
      }
    }
  },
  computed: {
    headers () {
      return [
        { sortable: false, class: 'accent', show: true },
        { text: 'Ad Name', align: 'left', sortable: true, value: 'ad_name', class: 'accent white--text', width: 'auto', show: true },
        { text: 'Ad Start - Ad End ', align: 'center', sortable: true, value: 'ad_start_date', class: 'accent white--text', width: 'auto', show: true },
        { text: 'Last Date for Shippers', align: 'start', sortable: false, value: 'last_shipper_date', class: 'accent white--text', show: this.tenant !== 'pricechopper' },
        { text: 'Last Order Date', align: 'start', sortable: false, value: 'last_order_date', class: 'accent white--text', show: true },
        { text: 'Ordering Status', align: 'center', sortable: false, value: 'ordering_status', class: 'accent white--text', show: true },
        { text: 'Ad Details', align: 'center', sortable: false, value: 'ad_details', class: 'accent white--text', show: true }
      ].filter(header => header.show)
    },
    currentDate() {
      return moment().format(this.$dateConfig.date_format)
    },
    disablePrevious () {
      return ((this.page - 1) * this.pageSize) === 0
    },
    disableNext () {
      return this.adPlanners.length < this.pageSize
    },
    formattedAdDate () {
      return this.adDate ? this.moment(this.adDate).format(this.$dateConfig.date_display) : ''
    },
    adGroups () {
      return this.$store.getters.adGroups
    },
    userAdGroups () {
      if (this.adGroups.length > 0) {
        let groups = this.adGroups
        if (this.isNonAdminUser) {
          groups = groups.filter(group => {
            return this.userAdGroupPartyIds.includes(group?.id)
          })
        }
        if (this.limitAccessByRelatedStores) {
          groups = groups.filter(group => {
            return this.activeUserRelatedPartyIds.includes(group?.id)
          })
        }
        return groups.sort(this.sortByName)
      }
      return []
    },
    tenantStores () {
      return this.$store.getters.tenantStores
    },
    activeUserRelatedPartyIds () {
      return this.$store.getters.activeUserRelatedPartyIds
    }
  },
  methods: {
    orderingInfo(adStartDate) {
      let orderInfo = {
        lastOrderDate: moment(adStartDate).subtract(4, 'weeks').format('YYYY-MM-DD'),
        lastShipperOrderDate: moment(adStartDate).subtract(5, 'weeks').format('YYYY-MM-DD')
      }
      if (this.tenant === "pricechopper") {
        orderInfo = {
          lastOrderDate: moment(adStartDate).subtract(5, 'weeks').subtract(2, 'days').format('YYYY-MM-DD'),
          lastShipperOrderDate: moment(adStartDate).subtract(5, 'weeks').subtract(2, 'days').format('YYYY-MM-DD')
        }
      }
      return orderInfo
    },
    orderingDates(adStartDate, context) {
      const { lastOrderDate, lastShipperOrderDate } = this.orderingInfo(adStartDate)
      return context === 'last_order' ? lastOrderDate : lastShipperOrderDate
    },
    statusDisplay(adStartDate, context) {
      const { lastOrderDate, lastShipperOrderDate } = this.orderingInfo(adStartDate)
      if (this.currentDate <= lastShipperOrderDate) {
        return context === 'text' ? 'All Items' : 'green'
      } else if ((this.currentDate >= lastShipperOrderDate) && (this.currentDate <= lastOrderDate)) {
        return context === 'text' ? 'No Shippers' : 'orange'
      } else {
        return context === 'text' ? 'Closed' : 'grey darken-1'
      }
    },
    async getStoreParties () {
      const partyId = this.selectedAdGroup?.id
      if (!partyId) return
      let stores = []
      try {
        if (this.tenant === 'pricechopper' && this.selectedAdGroup?.name === 'PCE Ad Group') {
          stores = this.tenantStores
        } else {
          const res = await Party.getStoresByAdGroup(partyId)
          if (res?.data?.length > 0) {
            stores = res.data.map(party => {
              party.id = party.store_party_id
              party.name = `#${party.wholesaler_store_number || ''} ${party.store_name}`
              return party
            })
          }
        }
        if (this.limitAccessByRelatedStores) {
          stores = stores.filter(result => {
            return this.activeUserRelatedPartyIds.includes(result.id)
          })
        }
        this.storeParties = stores
        if (this.storeParties.length > 0) {
          this.selectedStore = sessionStorage.selected_store
            ? JSON.parse(sessionStorage.selected_store)
            : this.storeParties[0]
        }
      } catch (err) {
        this.handleError(err)
      }
    },
    async getAdPlanners () {
      this.loading = true
      try {
        const partyId = this.selectedAdGroup.id
        const res = await AdRetails.getAdPlanners(partyId, this.from, this.pageSize)
        this.adPlanners = res?.data || []
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
      }
    },
    viewAdPlanner (adPlanner) {
      if (!this.selectedAdGroup?.id || !this.selectedStore?.id) {
        return this.handleError(new Error('You must select both a store and an ad group to continue.'))
      }
      sessionStorage.setItem('store_id', this.selectedStore.id)
      sessionStorage.setItem('store_name', this.selectedStore.name)
      return this.$router.push({
        name: 'RetailAdItems',
        params: {
          ad_id: adPlanner.ad_id,
          adPlanner: adPlanner,
          party: this.selectedAdGroup,
          store_id: this.selectedStore.id,
          store_name: this.selectedStore.name
        }
      })
    }
  }
}
</script>
