import axios from 'axios'
import AuthToken from '@/auth/auth-token'
import qs from 'qs'

class AdRetails {
  constructor () {
    const base = axios.create({
      baseURL: process.env.VUE_APP_PROMO_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json'
      }
    })
    this.adRetails = base
  }

  async getAdPlanners (party_id, offset = 0, limit = 25) {
    await AuthToken.setAuth(this.adRetails, {
      audience: process.env.VUE_APP_PROMO_AUDIENCE
    })
    return this.adRetails({
      url: '/ad_planners',
      method: 'GET',
      params: { party_id, offset, limit }
    })
  }

  async getPages (ad_id) {
    await AuthToken.setAuth(this.adRetails, {
      audience: process.env.VUE_APP_PROMO_AUDIENCE
    })
    return this.adRetails({
      url: `/ad_items_page_num/${ad_id}`,
      method: 'GET'
    })
  }

  async getDepartments (ad_id) {
    await AuthToken.setAuth(this.adRetails, {
      audience: process.env.VUE_APP_PROMO_AUDIENCE
    })
    return this.adRetails({
      url: `/ad_items_department/${ad_id}`,
      method: 'GET'
    })
  }

  async getOrderGridData(
    searchParams,
    party_id,
    ad_id,
    storeDeliveryDays,
    offset = 0,
    limit = 50
  ) {
    await AuthToken.setAuth(this.adRetails, {
      audience: process.env.VUE_APP_PROMO_AUDIENCE
    })
    return this.adRetails({
      url: `/build_order_grid/${party_id}/${ad_id}`,
      method: "GET",
      params: {
        ...searchParams,
        delivery_days: storeDeliveryDays,
        offset: offset,
        limit: limit
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false, encode: false })
      }
    })
  }

  async getShipperComponents (ad_id, feature_id, shipper_id) {
    await AuthToken.setAuth(this.adRetails, {
      audience: process.env.VUE_APP_PROMO_AUDIENCE
    })
    return this.adRetails({
      url: `/ad_planner/shipper_components/${ad_id}/${feature_id}`,
      method: 'GET',
      params: { shipper_id }
    })
  }

  async updateRetailAd (ad_id, item_data) {
    await AuthToken.setAuth(this.adRetails, {
      audience: process.env.VUE_APP_PROMO_AUDIENCE
    })
    return this.adRetails({
      url: `/ad_planner/${ad_id}`,
      method: 'PUT',
      data: item_data
    })
  }
}

export default new AdRetails()
